import { Box, Button, Container, Divider, Paper, Typography } from "@mui/material";
import { Link as MuiLink } from "@mui/material";

import { redirect } from "react-router";
import { Link } from "react-router-dom";
import { useLoaderData } from "react-router-typesafe";

import { Course, getCourses, getSessionState } from "~/api";
import { LearnLogo } from "~/components/Logo";
import { NavigationIndicator } from "~/components/NavigationIndicator";
import { localize } from "~/localize";

export async function loader() {
	let state = await getSessionState();

	if (!state.hasIdentity) {
		return redirect("/");
	} else if (!state.isAuthenticated && state.tokenSent) {
		return redirect("/verify-token");
	} else if (state.isAuthenticated && !state.hasPersonalia) {
		return redirect("/registration");
	}

	let courses = await getCourses();

	return { state, courses };
}

export function Component() {
	let { state, courses } = useLoaderData<typeof loader>();

	return (
		<Container maxWidth="sm">
			<NavigationIndicator />
			<Paper sx={{ py: 2 }} className="flow">
				<LearnLogo />
				<Typography px={2} textAlign="center">
					{localize("Du er logget inn som", "You are logged in as")} <strong>{state.name}</strong>
					{localize(". Her er kursene som er tilgjengelig for deg:", ". These are the courses available to you:")}
				</Typography>
				<Divider />
				{courses.map((course, idx) => (
					<CourseItem key={idx} course={course} />
				))}
				<Box display="flex" justifyContent="center">
					<Button component={Link} to="/logout" size="large" variant="outlined" type="submit">
						{localize("Logg ut", "Log out")}
					</Button>
				</Box>
			</Paper>
		</Container>
	);
}

interface CourseItemProps {
	course: Course;
}

function CourseItem({ course }: CourseItemProps) {
	let { CompletedDate, CourseID, CurrentSlide, Description, Title } = course;
	let buttonTxt = localize("Start kurset", "Take the course");
	let courseLink = "/api/personnel/course/" + CourseID + "/signup";

	if (!CompletedDate && CurrentSlide) {
		courseLink = "/course?id=" + CourseID + "#/" + CurrentSlide;
	}

	if (CompletedDate) {
		buttonTxt = localize("Ta på ny", "Take again");
	} else if (CurrentSlide) {
		buttonTxt = localize("Fortsett", "Continue");
	}

	return (
		<>
			<Box px={3} className="flow">
				<Typography variant="h3" component="h2">
					{Title} {CompletedDate && localize("(Fullført)", "(Completed)")}
				</Typography>
				<Typography>{Description}</Typography>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Button variant="outlined" component={MuiLink} href={courseLink}>
						{buttonTxt}
					</Button>
					{CompletedDate && (
						<MuiLink href="/api/personnel/course/11/diploma" target="_blank" rel="noopener noreferrer">
							{localize("Last ned kursbevis", "Download diploma")}
						</MuiLink>
					)}
				</Box>
			</Box>
			<Divider />
		</>
	);
}
